const labels = ['4/2024', '5/2024', '6/2024', '7/2024', '8/2024', '9/2024'];

export const dataSalesByTicketType = {
	labels,
	datasets: [
		{
			label: 'Nhạc sống',
			data: ['15', '28', '30', '12', '4', '2'],
			backgroundColor: 'rgb(255, 99, 132)',
		},
		{
			label: 'Sân khấu nghệ thuật',
			data: ['18', '12', '18', '8', '10', '2'],
			backgroundColor: 'rgb(75, 192, 192)',
		},
		{
			label: 'Hội nghị - triển lãm',
			data: ['22', '12', '26', '8', '3', '2'],
			backgroundColor: 'rgb(53, 162, 235)',
		},
		{
			label: 'Du lịch - khám phá',
			data: ['20', '22', '12', '10', '8', '2'],
			backgroundColor: 'rgb(53, 162, 75)',
		},
	],
};

export const dataTicketAvailability = {
	labels: ['Còn trống', 'Đã bán', 'Đã hủy'],
	datasets: [
		{
			label: '# of Votes',
			data: [480, 316, 436],
			backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
			borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
			borderWidth: 1,
		},
	],
};

export const dataPaymentMethodDistribution = {
	labels: ['Paypal', 'Stripe', 'Amazon'],
	datasets: [
		{
			label: 'Số lần thanh toán',
			data: [5, 2, 0],
			backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
			borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
			borderWidth: 1,
		},
	],
};

export const dataRefundsAndCancellations = {
	labels: labels,
	datasets: [
		{
			label: 'Hoàn tiền',
			data: [65, 59, 80, 81, 56, 55, 40],
			fill: false,
			borderColor: 'rgb(75, 192, 192)',
			tension: 0.1,
		},
	],
};

export const dataTicketTrend = {
	labels: labels,
	datasets: [
		{
			label: 'Sân khấu nghệ thuật',
			data: ['18', '12', '18', '8', '10', '2'],
			backgroundColor: 'rgb(75, 192, 192)',
			fill: false,
			tension: 0.1,
		},
		{
			label: 'Nhạc sống',
			data: ['15', '28', '30', '12', '4', '2'],
			backgroundColor: 'rgb(255, 99, 132)',
			fill: false,
			tension: 0.1,
		},
		{
			label: 'Hội nghị - triển lãm',
			data: ['22', '12', '26', '8', '3', '2'],
			backgroundColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgb(255, 99, 132)',
			fill: false,
			tension: 0.1,
		},
		{
			label: 'Du lịch - khám phá',
			data: ['20', '22', '12', '10', '8', '2'],
			backgroundColor: 'rgb(53, 162, 75)',
			fill: false,
			tension: 0.1,
		},
	],
};
