import { Button, Card, Datepicker, Label } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
	BarElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, ArcElement);

import { Line, Doughnut, Bar, Pie } from 'react-chartjs-2';
import {
	dataPaymentMethodDistribution,
	dataRefundsAndCancellations,
	dataSalesByTicketType,
	dataTicketAvailability,
	dataTicketTrend,
} from '../../../data/dashboard.data';

export const options = {
	plugins: {
		title: {
			display: true,
			text: 'Chart.js Bar Chart - Stacked',
		},
	},
	responsive: true,
	scales: {
		x: {
			stacked: true,
		},
		y: {
			stacked: true,
		},
	},
};

export default function DashboardTab() {
	const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
	const data = {
		labels: labels,
		datasets: [
			{
				label: 'My First Dataset',
				data: [65, 59, 80, 81, 56, 55, 40],
				fill: false,
				borderColor: 'rgb(75, 192, 192)',
				tension: 0.1,
			},
		],
	};
	const [user, setUser] = useState(() => {
		const userJson = localStorage.getItem('user');
		return userJson ? JSON.parse(userJson) : null;
	});

	const [endDate, setEndDate] = useState(new Date());
	const [startDate, setStartDate] = useState(new Date(endDate.setMonth(endDate.getMonth() - 6)));

	const [dataPayment, setDataPayment] = useState({ labels: [], datasets: [] });
	const [dataProfit, setDataProfit] = useState({ labels: [], datasets: [] });

	const [loadingPayment, setLoadingPayment] = useState(false);

	const fetchDataPayment = async (userId) => {
		const options = {
			url: `${process.env.REACT_APP_API_URL}/api/checkout/paymentStatistic/${userId}`,
			method: 'GET',
		};
		await axios
			.request(options)
			.then((response) => {
				const result = response.data;

				if (result.success) {
					setDataPayment(result.data);
				}

				console.log(result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const fetchDataProfit = async (userId, formDate, toDate) => {
		const options = {
			url: `${process.env.REACT_APP_API_URL}/api/checkout/profitByDate/${userId}`,
			method: 'GET',
			params: {
				formDate,
				toDate,
			},
		};
		await axios
			.request(options)
			.then((response) => {
				const result = response.data;

				if (result.success) {
					setDataProfit(result.data);
				}

				console.log(result);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		fetchDataPayment(user._id);
		const fromDate = new Date('2024-01-01');
		const toDate = new Date('2024-12-31');
		fetchDataProfit(user._id, fromDate, toDate);
	}, []);

	return (
		<>
			<section className="px-6 py-4 mt-[-10px]">
				<div className="grid grid-cols-12 gap-4 mb-10">
					<div className="col-span-6 grow">
						<div className="relative pt-2">
							<h1 className="text-2xl whitespace-nowrap">Thống kê</h1>
						</div>
						<p className="whitespace-nowrap overflow-hidden text-xs text-gray-400 ">API ID: 315F4</p>
					</div>
					<div className="col-span-6 grid grid-cols-2 gap-4">
						<div className="">
							<Label value="Ngày bắt đầu" />
							<Datepicker
								defaultDate={startDate}
								defaultValue={new Date(new Date().setMonth(new Date().getMonth() - 6))}
								onChange={(e) => setStartDate(e.target.value)}
								language="vi-VN"
								title="Ngày bắt đầu"
							/>
						</div>
						<div className="">
							<Label value="Ngày kết thúc" />
							<Datepicker
								defaultValue={new Date()}
								onChange={(e) => setEndDate(e.target.value)}
								language="vi-VN"
								title="Ngày kết thúc"
							/>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-12 gap-4 mt-4 mb-4">
					<Card className="w-full col-span-3">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Vé có sẵn</h5>
						<Doughnut data={dataTicketAvailability} />
					</Card>
					<Card className="w-full col-span-3">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
							Phương thức thanh toán
						</h5>

						<Pie data={dataPayment} />
					</Card>
					<Card className="w-full col-span-6">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
							Bán vé theo loại
						</h5>
						<Bar options={options} data={dataSalesByTicketType} />
					</Card>

					<Card className="w-full col-span-4">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
							Hoàn tiền và hủy bỏ
						</h5>
						<Line data={dataRefundsAndCancellations} />
					</Card>
					<Card className="w-full col-span-4">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Doanh thu</h5>

						<Line data={dataProfit} />
					</Card>
					<Card className="w-full col-span-4">
						<h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Xu hướng vé</h5>

						<Line data={dataTicketTrend} />
					</Card>
				</div>
			</section>
		</>
	);
}
