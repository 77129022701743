import { useEffect, useState } from 'react';
import { Banner } from '../../components/Client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TicketList from '../../components/Client/TicketList';
import AreaGraph from '../../components/Client/AreaGraph';
import CustomerInfo from '../../components/Client/CustomerInfo';
import { BiCreditCard, BiLogoPaypal, BiLogoAmazon } from 'react-icons/bi';
import axios from 'axios';
import { Toast, Spinner, Button, Modal, ListGroup, Card } from 'flowbite-react';
import { HiExclamation, HiTicket } from 'react-icons/hi';
import { checkAuth } from '../../utils';
import SeatGrid from '../../components/Client/SeatGrid';

export default function BookingScreen() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkAuthAsync = async () => {
			const isAuth = await checkAuth();

			if (!isAuth) {
				localStorage.clear();
				navigate('/login');
			}
		};

		checkAuthAsync();
	}, []);
	const [openModal, setOpenModal] = useState(false);
	const [openSeatModal, setOpenSeatModal] = useState(false);
	const [ticketMap, setTicketMap] = useState([]);
	const [groupTickets, setGroupTickets] = useState([]);
	const [user, setUser] = useState(() => {
		const userJson = localStorage.getItem('user');
		return userJson ? JSON.parse(userJson) : null;
	});

	const [bookingTickets, setBookingTickets] = useState([]);

	const [selectedTickets, setSelectedTickets] = useState([]);

	const [paymentActive, setPaymentActive] = useState('stripe');
	const [formData, setFormData] = useState({
		items: [],
		temporary_cost: 0,
		payment_type: paymentActive,
	});
	const [checkoutStage, setCheckoutStage] = useState(1);
	const [errorMessage, setErrorMessage] = useState('');
	const [isCreatingBooking, setIsCreatingBooking] = useState(false);

	const { event_slug } = useParams();
	const [event, setEvent] = useState(null);

	const [areas, setAreas] = useState([]);

	const fetchArea = async (eventId) => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}/api/area/event/${eventId}`)
			.then((response) => {
				const result = response.data;
				if (result.success) {
					setAreas(result.areas);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}

		// console.log(user)

		const fetchDataEvent = async () => {
			const options = {
				url: `${process.env.REACT_APP_API_URL}/api/event/view/${event_slug}`,
				method: 'GET',
			};

			await axios
				.request(options)
				.then((response) => {
					const result = response.data;

					if (result.success) {
						console.log(result.event);
						fetchArea(result.event._id);
						setEvent({ ...result.event });
					}

					console.log(result);
				})
				.catch((err) => {
					navigate('/');
					console.log(err);
				});
		};

		fetchDataEvent();
	}, []);

	const handleStage1 = () => {
		if (paymentActive === 'amazon') {
			setErrorMessage('Amazon Pay đang được bảo trì. Vùi lòng chọn phương thức thanh toán khác.');
			return;
		}

		setIsCreatingBooking(true);
		const createBooking = async () => {
			const options = {
				url: `${process.env.REACT_APP_API_URL}/api/booking/create`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				params: {},
				data: JSON.stringify({ ...formData, customer: user }),
			};

			await axios
				.request(options)
				.then((response) => {
					const result = response.data;
					navigate(`/event/${event_slug}/booking/${result.booking_id}/checkout`);

					console.log(result);
				})
				.catch((err) => {
					console.log(err);
					setTimeout(() => {
						setErrorMessage(err.response.data.msg);
					}, 1000);
				})
				.finally(() => {
					setTimeout(() => {
						setIsCreatingBooking(false);
					}, 1000);
				});
		};

		createBooking();
	};

	const fetchSoldTicketByTicketTypeId = async (ticketTypeId) => {
		const options = {
			url: `${process.env.REACT_APP_API_URL}/api/booking/ticketType/${ticketTypeId}`,
			method: 'GET',
		};
		await axios.request(options).then((response) => {
			const result = response.data;

			if (result.success) {
				setBookingTickets(result.bookings);
			}
		});
	};

	const handleOnClickTicket = async (ticketMap, id, price, ticketName, position) => {
		await fetchSoldTicketByTicketTypeId(id);
		setTicketMap({ ticketMap, id, price, ticketName, h: position.h, w: position.w });
		setOpenSeatModal(true);
	};

	const groupByTicketTypes = (items) => {
		const grouped = items.reduces((acc, item) => {
			const group = acc.find((group) => group.ticket_types.ticket_name);
		}, []);
	};

	return (
		<>
			{event && (
				<section className="mt-[6.5rem] min-h-screen">
					<Banner event={event} />

					<div className="booking-container w-[65%] mx-auto">
						<div className="grid grid-cols-6 gap-4 my-5">
							{/* Stage 1 */}
							<div className="desktop:col-span-4 laptop:col-span-3 col-span-6 bg-zinc-800 p-4 self-start">
								<div className="flex flex-col gap-4">
									<table className="table w-[100%]">
										<tbody>
											<tr className="table-row border-b border-emerald-300 ">
												<td className="text-md font-medium text-emerald-300 py-2">
													Thông tin vé
												</td>

												<td colSpan={5} className="text-sm py-2 text-right text-gray-300">
													{event.start_time}-{event.end_time}
												</td>
											</tr>
											<div className="py-2"></div>
											<TicketList
												ticket_types={event.ticket_types}
												qty={true}
												data={formData}
												is_seat_allocation={event.is_seat_allocation}
												setData={setFormData}
												handleOnclick={handleOnClickTicket}
											/>
										</tbody>
									</table>
									{event.is_seat_allocation && (
										<>
											<Button className="bg-main" onClick={() => setOpenModal(true)}>
												Sơ đồ vé
											</Button>

											<Modal size="7xl" show={openModal} onClose={() => setOpenModal(false)}>
												<Modal.Header>Sơ đồ vé</Modal.Header>
												<Modal.Body>
													<AreaGraph areas={areas} ticketTypes={event.ticket_types} />
												</Modal.Body>
												<Modal.Footer>
													<Button color="gray" onClick={() => setOpenModal(false)}>
														Đóng
													</Button>
												</Modal.Footer>
											</Modal>

											<Modal
												size="7xl"
												show={openSeatModal}
												onClose={() => setOpenSeatModal(false)}
											>
												<Modal.Header>Sơ đồ chỗ ngồi</Modal.Header>
												<Modal.Body>
													<SeatGrid
														selectedTickets={selectedTickets}
														setSelectedTickets={setSelectedTickets}
														ticketMaps={ticketMap}
														setFormData={setFormData}
														formData={formData}
														bookingTickets={bookingTickets}
														setGroupTickets={setGroupTickets}
													/>
													<div className="flex">
														<ListGroup className="w-48 mr-5">
															<ListGroup.Item>
																<span className="bg-main p-2"></span>
																<p className="ml-2">Còn trống</p>
															</ListGroup.Item>
														</ListGroup>
														<ListGroup className="w-48 mr-5">
															<ListGroup.Item>
																<span className="bg-blue-600 p-2"></span>
																<p className="ml-2">Đã chọn</p>
															</ListGroup.Item>
														</ListGroup>
														<ListGroup className="w-48 mr-5">
															<ListGroup.Item>
																<span className="bg-red-500 p-2"></span>
																<p className="ml-2">Đã được mua</p>
															</ListGroup.Item>
														</ListGroup>
														<ListGroup className="w-48 mr-5">
															<ListGroup.Item>
																<span className="bg-yellow-300 p-2"></span>
																<p className="ml-2">Đang được mua</p>
															</ListGroup.Item>
														</ListGroup>
													</div>
												</Modal.Body>

												<Modal.Footer>
													<Button color="gray" onClick={() => setOpenSeatModal(false)}>
														Đóng
													</Button>
												</Modal.Footer>
											</Modal>
										</>
									)}

									<div className="w-[100%]">
										<div className="border-b border-emerald-300 ">
											<div className="text-md font-medium text-emerald-300 py-2">
												Phương thức thanh toán
											</div>
										</div>
										<div className="py-2"></div>
										<div className="grid grid-cols-3 gap-5 p-4">
											<div className="col-span-1">
												<button
													className={`rounded-md flex flex-col items-center justify-center w-full
                                                            border-2 border-${
																paymentActive === 'stripe' ? 'emerald' : 'slate'
															}-300 py-6 gap-2
                                                            hover:border-emerald-300 hover:opacity-90 cursor-pointer`}
													onClick={() => {
														setPaymentActive('stripe');
														setFormData({ ...formData, payment_type: 'stripe' });
													}}
												>
													<BiCreditCard size={40} />
													<p className="desktop:block hidden text-sm font-medium">
														Thanh toán với thẻ Visa
													</p>
												</button>
											</div>
											<div className="col-span-1">
												<button
													className={`rounded-md flex flex-col items-center justify-center w-full 
                                                            border-2 border-${
																paymentActive === 'paypal' ? 'emerald' : 'slate'
															}-300 py-6 gap-2
                                                            hover:border-emerald-300 hover:opacity-90 cursor-pointer `}
													onClick={() => {
														setPaymentActive('paypal');
														setFormData({ ...formData, payment_type: 'paypal' });
													}}
												>
													<BiLogoPaypal size={40} />
													<p className="desktop:block hidden text-sm font-medium">
														Thanh toán với Paypal
													</p>
												</button>
											</div>
											<div className="col-span-1">
												<button
													className={`rounded-md flex flex-col items-center justify-center w-full 
                                                            border-2 border-${
																paymentActive === 'amazon' ? 'emerald' : 'slate'
															}-300 py-6 gap-2
                                                            hover:border-emerald-300 hover:opacity-90 cursor-pointer `}
													onClick={() => {
														setPaymentActive('amazon');
														setFormData({ ...formData, payment_type: 'amazon' });
													}}
												>
													<BiLogoAmazon size={40} />
													<p className="desktop:block hidden text-sm font-medium">
														Thanh toán với Amazon
													</p>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="relative desktop:col-span-2 laptop:col-span-3 col-span-6 w-[100%]">
								<div className="w-[100%] px-4 py-4 bg-zinc-800">
									<div className="w-[100%] pb-3">
										<CustomerInfo user={user} />
									</div>

									<div className="border-b border-emerald-300 flex items-center justify-between">
										<div className="text-md font-medium text-emerald-300 py-2">
											Thông tin đặt vé
										</div>
									</div>

									<div className="py-2 border-b border-dashed mt-1 border-gray-300">
										{formData.items.length === 0 ? (
											<>-</>
										) : (
											<>
												{!event.is_seat_allocation &&
													formData.items.map((tp, index) => {
														return (
															<table
																aria-hidden="true"
																key={index}
																className="table w-[100%]"
															>
																<tbody>
																	<tr className="table-row">
																		<td className="text-sm font-base p-1">
																			{tp.ticket_name}{' '}
																			{event.is_seat_allocation && tp.name} <br />{' '}
																			{tp.price.toLocaleString('vi-vn')}đ
																		</td>
																		<td className="text-sm text-right">
																			{tp.qty} <br />{' '}
																			{(tp.price * tp.qty).toLocaleString(
																				'vi-vn',
																			)}
																			đ
																		</td>
																	</tr>
																</tbody>
															</table>
														);
													})}

												{event.is_seat_allocation &&
													groupTickets.map((group, index) => {
														console.log(group);
														return (
															<table
																aria-hidden="true"
																key={index}
																className="table w-[100%]"
															>
																<tbody>
																	<tr className="table-row">
																		<td className="text-sm font-base p-1">
																			{group.ticket_name}{' '}
																			{group.items.map((item, index) => (
																				<span
																					className="px-1.5 bg-white m-0.5 text-black-500"
																					key={index}
																				>
																					{item.name}
																				</span>
																			))}
																			<br />{' '}
																			{group.items[0].price.toLocaleString(
																				'vi-vn',
																			)}
																			đ
																		</td>
																		<td className="text-sm text-right p-2">
																			{group.items.length} <br />{' '}
																			{(
																				group.items[0].price *
																				group.items.length
																			).toLocaleString('vi-vn')}
																			đ
																		</td>
																	</tr>
																</tbody>
															</table>
														);
													})}
											</>
										)}
									</div>
								</div>
								<div className="w-full bg-gray-200 font-medium text-slate-700 px-4 h-12 flex flex-row items-center justify-between ">
									<span className="text-sm">Tạm tính</span>
									<span className="text-sm">{formData.temporary_cost.toLocaleString('vi-vn')}đ</span>
								</div>
								<button
									onClick={() => {
										if (formData.items.length === 0) {
											setErrorMessage('Vui lòng chọn vé trước khi thanh toán');
											return;
										}

										setErrorMessage('');
										handleStage1();
									}}
									className="bg-main py-3 text-center rounded w-[100%] mt-5"
								>
									{isCreatingBooking ? <Spinner color="success" /> : 'Tiếp tục'}
								</button>
							</div>
						</div>
					</div>
					<div className="py-3"></div>
				</section>
			)}

			<div className={`fixed right-3 bottom-5 ${errorMessage !== '' ? 'block' : 'hidden'}`}>
				<Toast>
					<div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
						<HiExclamation className="h-5 w-5" />
					</div>
					<div className="ml-3 text-xs font-normal">{errorMessage}</div>
					{/* <Toast.Toggle /> */}
				</Toast>
			</div>
		</>
	);
}
